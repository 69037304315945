<p-table
	[value]="pays"
	responsiveLayout="scroll"
	[rows]="10"
	[loading]="spinnerService.showSpinner"
	styleClass="p-datatable-gridlines"
>
	<ng-template pTemplate="header">
		<tr>
			<th>Name</th>
			<th>Type</th>
			<th>Rate</th>
			<th class="text-button-header">
				<span class="left">Active</span> 
				<button
					pButton
					pRipple
					type="button"
					pTooltip="Assign Pay to Operation"
					tooltipPosition="left"
					icon="pi pi-plus"
					(click)="addPayToOperation()"
					class="p-button-rounded p-button-success text-xl text-right"
				></button>
			</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-row>
		<tr>
			<td>{{ row.name }}</td>
			<td>{{ row.type }}</td>
			<td>{{ row.rate | currency }}</td>
			<td>
				<p-inputSwitch
					(onChange)="togglePays(row)"
					[disabled]="!row.active"
					[(ngModel)]="row.active">
				</p-inputSwitch>
			</td>
		</tr>
	</ng-template>
</p-table>

<p-dialog
	[modal]="true"
	[draggable]="false"
	[dismissableMask]="true"
	(onHide)="closeModal()"
	header="Assign Pay Rate to Operation"
	[(visible)]="modal"
	[breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
	[style]="{ width: '30vw' }"
>
	<!-- Initial Pane -->
	@if (selectMode === SelectMode.Start) {
	<div class="flex justify-content-between">
		<p-button
			class="flex flex-grow-1"
			(click)="setPickerMode(SelectMode.Single)"
			label="Add Single Pay Rate"
			styleClass="p-button-primary flex-grow-1"
		>
		</p-button>
		<p-button
			class="flex flex-grow-1 ml-5"
			(click)="setPickerMode(SelectMode.Branch)"
			label="Add Batch of Rates"
			styleClass="p-button-primary flex-grow-1"
		>
		</p-button>
	</div>
	}

	<!-- Single Select -->
	@if (selectMode === SelectMode.Single) {
	<div>
		<p-dropdown
			[options]="availablePays"
			optionLabel="name"
			appendTo="body"
			[style]="{ width: '100%' }"
			placeholder="Select a Pay"
			[(ngModel)]="selectedPay"
			[filter]="true"
			filterBy="name"
			[showClear]="true"
		>
			<ng-template let-pay pTemplate="item">
				[{{ pay.type }}]
				{{ pay.effectiveStartDate | date : "dd/MM/yyyy" }} -
				{{ pay.effectiveEndDate | date : "dd/MM/yyyy" }}
				<strong>{{ pay.name }}</strong>
				({{ pay.rate | currency : "USD" }})
			</ng-template>
		</p-dropdown>
		<div class="text-right mt-4">
			<p-button
				icon="pi pi-angle-left"
				(click)="setPickerMode(SelectMode.Start)"
				label="Back"
				styleClass="p-button-secondary p-button-outlined"
			>
			</p-button>
			<p-button
				icon="pi pi-check"
				(click)="assignPayToOperation()"
				label="Save"
				[disabled]="selectedPay === null"
				styleClass="p-button-success ml-2"
			>
			</p-button>
		</div>
	</div>
	}

	<!-- Batch Select: Branch -->
	@if (selectMode === SelectMode.Branch) {
	<div>
		<div class="field">
			<label for="branches">Branches</label>
			<p-multiSelect
				inputId="branches"
				[options]="branches"
				optionLabel="name"
				appendTo="body"
				[style]="{ width: '100%' }"
				placeholder="Select Multiple or None"
				[(ngModel)]="selectedBranches"
			>
			</p-multiSelect>
		</div>
		<div class="text-right">
			<p-button
				icon="pi pi-angle-left"
				(click)="setPickerMode(SelectMode.Start)"
				label="Back"
				styleClass="p-button-secondary p-button-outlined"
			>
			</p-button>
			<p-button
				icon="pi pi-angle-right"
				(click)="setPickerMode(SelectMode.Active)"
				label="Next"
				styleClass="p-button-primary-button-outlined ml-2"
			>
			</p-button>
		</div>
	</div>
	}

	<!-- Batch Select: Active Status -->
	@if (selectMode === SelectMode.Active) {
	<div>
		<div class="field">
			<label for="active">Active</label>
			<p-selectButton
				inputId="active"
				[options]="activeOptions"
				[(ngModel)]="active"
			>
			</p-selectButton>
		</div>
		<div class="text-right">
			<p-button
				icon="pi pi-angle-left"
				(click)="setPickerMode(SelectMode.Branch)"
				label="Back"
				styleClass="p-button-secondary p-button-outlined"
			>
			</p-button>
			<p-button
				icon="pi pi-angle-right"
				(click)="setPickerMode(SelectMode.Type)"
				label="Next"
				styleClass="p-button-primary-button-outlined ml-2"
			>
			</p-button>
		</div>
	</div>
	}

	<!-- Batch Select: Type -->
	@if (selectMode === SelectMode.Type) {
	<div>
		<div class="field">
			<p-selectButton
				[options]="personnelTypes"
				[(ngModel)]="selectedPersonnelType"
			>
			</p-selectButton>
		</div>
		<div class="text-right">
			<p-button
				icon="pi pi-angle-left"
				(click)="setPickerMode(SelectMode.Active)"
				label="Back"
				styleClass="p-button-secondary p-button-outlined"
			>
			</p-button>
			<p-button
				icon="pi pi-angle-right"
				(click)="setPickerMode(SelectMode.Review)"
				label="Next"
				styleClass="p-button-primary-button-outlined ml-2"
			>
			</p-button>
		</div>
	</div>
	}

	<!-- Batch Select: Review -->
	@if (selectMode === SelectMode.Review) {
	<div>
		<p class="field">
			This batch will add all pay rates matching the following parameters:
		</p>
		<div class="field">
			<strong>{{
				selectedBranches?.length > 1 ? "Branches:" : "Branch:"
			}}</strong>
			@if (selectedBranches?.length === 1) {
			{{ " " + selectedBranches[0].name }}
			} @if (selectedBranches === null) { N/A } @if
			(selectedBranches?.length > 1 ) {
			<ul class="mt-0">
				@for (branch of selectedBranches; track branch; let i = $index)
				{
				<li>
					{{
						branch.name +
							(i < selectedBranches.length - 1 ? "," : "")
					}}
				</li>
				}
			</ul>
			}
		</div>
		<div class="field"><strong>Active:</strong> {{ active | yesNo }}</div>
		<div class="field">
			<strong>Personnel Type:</strong>
			@if (selectedPersonnelType !== null) {
			{{ " " + selectedPersonnelType }}
			} @if (selectedPersonnelType === null) { N/A }
		</div>
		<div class="text-right">
			<p-button
				icon="pi pi-angle-left"
				(click)="setPickerMode(SelectMode.Type)"
				label="Back"
				styleClass="p-button-secondary p-button-outlined"
			>
			</p-button>
			<p-button
				icon="pi pi-check"
				(click)="addBatch()"
				label="Add Batch"
				styleClass="p-button-success ml-2"
			>
			</p-button>
		</div>
	</div>
	}
</p-dialog>

<p-confirmDialog #cd [style]="{ width: '450px' }">
	<ng-template pTemplate="footer">
		<button
			type="button"
			class="p-button p-button-primary"
			pButton
			icon="pi pi-times"
			label="No"
			(click)="cd.reject()"
		></button>
		<button
			type="button"
			class="p-button p-button-danger p-button-outlined"
			pButton
			icon="pi pi-check"
			label="Yes"
			(click)="cd.accept()"
		></button>
	</ng-template>
</p-confirmDialog>
